// Generated by Framer (04e0834)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/XVUmpmPn1EPL0dzocT35/Ticker.js";
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["PIGvsPDpN"];

const serializationHash = "framer-UR7KF"

const variantClassNames = {PIGvsPDpN: "framer-v-1qgxk08"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({background, border, bulletColor, height, id, width, ...props}) => { return {...props, G0MD4HoGO: background ?? props.G0MD4HoGO ?? "var(--token-507279ad-bde7-462a-a198-cfa80d6c3183, rgb(0, 0, 0))", Hel50RTSp: border ?? props.Hel50RTSp ?? {borderColor: "var(--token-507279ad-bde7-462a-a198-cfa80d6c3183, rgb(0, 0, 0)) /* {\"name\":\"Black/0\"} */", borderStyle: "solid", borderWidth: 2}, JkGKAK6uM: bulletColor ?? props.JkGKAK6uM ?? "var(--token-4c2d4390-2a5c-44d9-8c88-e13922773f89, rgb(156, 255, 51))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;border?: Record<string, any>;bulletColor?: string;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Hel50RTSp, JkGKAK6uM, G0MD4HoGO, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PIGvsPDpN", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1qgxk08", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PIGvsPDpN"} ref={ref ?? ref1} style={{"--border-bottom-width": `${Hel50RTSp.borderBottomWidth ?? Hel50RTSp.borderWidth}px`, "--border-color": Hel50RTSp.borderColor, "--border-left-width": `${Hel50RTSp.borderLeftWidth ?? Hel50RTSp.borderWidth}px`, "--border-right-width": `${Hel50RTSp.borderRightWidth ?? Hel50RTSp.borderWidth}px`, "--border-style": Hel50RTSp.borderStyle, "--border-top-width": `${Hel50RTSp.borderTopWidth ?? Hel50RTSp.borderWidth}px`, backgroundColor: G0MD4HoGO, borderBottomLeftRadius: 48, borderBottomRightRadius: 48, borderTopLeftRadius: 48, borderTopRightRadius: 48, ...style}}><ComponentViewportProvider ><motion.div className={"framer-51c2f1-container"} layoutDependency={layoutDependency} layoutId={"EPMGrp7M7-container"}><Ticker alignment={"center"} direction={"bottom"} fadeOptions={{fadeAlpha: 0, fadeContent: true, fadeInset: 0, fadeWidth: 100, overflow: false}} gap={18} height={"100%"} hoverFactor={1} id={"EPMGrp7M7"} layoutId={"EPMGrp7M7"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} sizingOptions={{heightType: true, widthType: true}} slots={[<motion.div className={"framer-nl289k"} data-framer-name={"Bullet"} layoutDependency={layoutDependency} layoutId={"e8WHPZc5t"} style={{backgroundColor: JkGKAK6uM, borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24}}/>]} speed={10} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UR7KF.framer-1j7y7qy, .framer-UR7KF .framer-1j7y7qy { display: block; }", ".framer-UR7KF.framer-1qgxk08 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 72px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 36px; will-change: var(--framer-will-change-override, transform); }", ".framer-UR7KF .framer-51c2f1-container { flex: 1 0 0px; height: 1px; position: relative; width: 100%; }", ".framer-UR7KF .framer-nl289k { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 4px); overflow: hidden; position: relative; width: 4px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UR7KF.framer-1qgxk08 { gap: 0px; } .framer-UR7KF.framer-1qgxk08 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-UR7KF.framer-1qgxk08 > :first-child { margin-top: 0px; } .framer-UR7KF.framer-1qgxk08 > :last-child { margin-bottom: 0px; } }", ".framer-UR7KF[data-border=\"true\"]::after, .framer-UR7KF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 72
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Hel50RTSp":"border","JkGKAK6uM":"bulletColor","G0MD4HoGO":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOCR78yMt9: React.ComponentType<Props> = withCSS(Component, css, "framer-UR7KF") as typeof Component;
export default FramerOCR78yMt9;

FramerOCR78yMt9.displayName = "Ticker - Scroll";

FramerOCR78yMt9.defaultProps = {height: 72, width: 36};

addPropertyControls(FramerOCR78yMt9, {Hel50RTSp: {defaultValue: {borderColor: "var(--token-507279ad-bde7-462a-a198-cfa80d6c3183, rgb(0, 0, 0)) /* {\"name\":\"Black/0\"} */", borderStyle: "solid", borderWidth: 2}, title: "Border", type: ControlType.Border}, JkGKAK6uM: {defaultValue: "var(--token-4c2d4390-2a5c-44d9-8c88-e13922773f89, rgb(156, 255, 51)) /* {\"name\":\"Lime/60\"} */", title: "Bullet Color", type: ControlType.Color}, G0MD4HoGO: {defaultValue: "var(--token-507279ad-bde7-462a-a198-cfa80d6c3183, rgb(0, 0, 0)) /* {\"name\":\"Black/0\"} */", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerOCR78yMt9, [{explicitInter: true, fonts: []}, ...TickerFonts], {supportsExplicitInterCodegen: true})